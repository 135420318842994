// HEADER ICONS //
export const calendarIcon = (color: string) =>
    `<svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M8.08081 9H1.0101V3.5H8.08081V9Z
               M6.56566 0V1H2.52525V0H1.51515V1H1.0101C0.449495 1 0 1.445 0 2V9
               C0 9.26522 0.106421 9.51957 0.295852 9.70711
               C0.485282 9.89464 0.742206 10 1.0101 10H8.08081
               C8.3487 10 8.60563 9.89464 8.79506 9.70711
               C8.98449 9.51957 9.09091 9.26522 9.09091 9V2
               C9.09091 1.445 8.63636 1 8.08081 1H7.57576V0H6.56566Z
               M7.07071 5.5H4.54545V8H7.07071V5.5Z"
            fill="${color}"
        />
    </svg>`

export const calendarTimeIcon = (color: string) =>
    `<svg width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M6.90918 5.45455H7.65918V6.73636L8.87918 7.37727L8.50418 7.96818L6.90918 7.13182V5.45455Z
                   M8.90918 3.18182H1.90918V8.18182H4.24418
                   C4.02918 7.76818 3.90918 7.30455 3.90918 6.81818
                   C3.90918 5.97431 4.27793 5.165 4.93431 4.5683
                   C5.59068 3.97159 6.48092 3.63636 7.40918 3.63636
                   C7.94418 3.63636 8.45418 3.74545 8.90918 3.94091V3.18182Z
                   M1.90918 9.09091C1.35418 9.09091 0.90918 8.68182 0.90918 8.18182V1.81818
                   C0.90918 1.31364 1.35418 0.909091 1.90918 0.909091H2.40918V0H3.40918V0.909091H7.40918V0H8.40918V0.909091H8.90918
                   C9.1744 0.909091 9.42875 1.00487 9.61629 1.17536
                   C9.80382 1.34585 9.90918 1.57708 9.90918 1.81818V4.59091
                   C10.5292 5.16364 10.9092 5.95 10.9092 6.81818
                   C10.9092 7.66205 10.5404 8.47136 9.88405 9.06807
                   C9.22768 9.66477 8.33744 10 7.40918 10
                   C6.45418 10 5.58918 9.65455 4.95918 9.09091H1.90918Z
                   M7.40918 4.61364C6.76603 4.61364 6.14922 4.8459 5.69445 5.25933
                   C5.23967 5.67277 4.98418 6.2335 4.98418 6.81818
                   C4.98418 8.03636 6.06918 9.02273 7.40918 9.02273
                   C7.72764 9.02273 8.04297 8.9657 8.33719 8.85492
                   C8.6314 8.74413 8.89873 8.58174 9.12391 8.37703
                   C9.3491 8.17232 9.52772 7.92929 9.64959 7.66182
                   C9.77146 7.39436 9.83418 7.10769 9.83418 6.81818
                   C9.83418 5.6 8.74918 4.61364 7.40918 4.61364Z" 
                fill="${color}" 
            />
        </svg>`

export const headerBgShadow = (color: string) =>
    `<svg width="527" height="389" viewBox="0 0 527 389" xmlns="http://www.w3.org/2000/svg">
                <path 
                    d="M248.855 15.823C185.15 86.3384 1.27402 352.775 1.27402 352.775
                       L374.372 47.2254C380.626 42.1033 385.546 35.5409 388.708 28.1008
                       L436.373 -84.0423C436.373 -84.0423 312.561 -54.6925 248.855 15.823Z"
                    fill="${color}" 
                    fill-opacity="0.08"
                />
                <path 
                    d="M199.984 -0.207532C156.079 84.0732 45.1911 388.215 45.1911 388.215
                       L329.364 -1.41088C334.127 -7.9425 337.236 -15.5318 338.424 -23.5284
                       L356.326 -144.059C356.326 -144.059 243.888 -84.4882 199.984 -0.207532Z"
                    fill="${color}" 
                    fill-opacity="0.08"
                />
            </svg>`

export const headerBg = `<svg width="475" height="52" viewBox="0 0 475 52" xmlns="http://www.w3.org/2000/svg">
                                <path d="M445 52H0V0H475L445 52Z" fill="#F3F4F6"/>
                        </svg>`

export const logoPlaceholder = `<svg width="200" height="56" viewBox="0 0 79 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                    d="M32.64 11.8805C32.6401 9.89018 32.0391 7.94624 30.9157 6.3033C29.7923 4.66036 28.1988 3.39501 26.3441 2.67301
                    C24.4894 1.951 22.4598 1.806 20.5213 2.25699C18.5827 2.70798 16.8256 3.73394 15.48 5.20048H3V31.5405H29.35V19.2305
                    C30.3842 18.3055 31.2118 17.1728 31.7787 15.9063C32.3456 14.6398 32.6391 13.2681 32.64 11.8805ZM27.57 11.8805
                    C27.572 12.8342 27.291 13.7671 26.7626 14.5611C26.2341 15.355 25.4821 15.9744 24.6015 16.3407C23.7209 16.7071 22.7515 16.804 21.8158 16.6191
                    C20.8802 16.4343 20.0204 15.976 19.3453 15.3023C18.6702 14.6286 18.2102 13.7698 
                    18.0234 12.8345C17.8366 11.8993 17.9315 10.9296 18.296 10.0483
                    C18.6605 9.16694 19.2783 8.41357 20.0711 7.8835C20.864 7.35343 21.7963 7.07049 22.75 7.07048C24.0266 7.07048 25.2511 7.57693 26.1547 8.47869
                    C27.0584 9.38045 27.5674 10.6039 27.57 11.8805ZM24.28 26.4705H8.07V10.2705H13.01C12.9146 10.802 12.8644 11.3406 12.86 11.8805
                    C12.8627 14.5027 13.9055 17.0167 15.7597 18.8709C17.6138 20.725 
                    20.1278 21.7679 22.75 21.7705C23.2627 21.7678 23.7743 21.7243 24.28 21.6405V26.4705Z"
                    fill="#394149"/>
                <path 
                    d="M38.6396 8.39453H41.8497V21.3945H38.6396V8.39453ZM43.1297 16.5045C43.1297 13.2845 45.1297 11.3545 48.1297 11.3545
                    C51.1297 11.3545 53.1297 13.2845 53.1297 16.5045C53.1297 19.7245 51.1996 
                    21.6745 48.1297 21.6745C45.0597 21.6745 43.1297 19.7945 43.1297 16.5045Z
                    M49.9496 16.5045C49.9496 14.7345 49.2596 13.6945 48.1596 13.6945C47.0596 13.6945 46.3797 14.6945 46.3797 16.5045
                    C46.3797 18.3145 47.0596 19.3045 48.1596 19.3045C49.2596 19.3045 49.9697 18.3145 49.9697 16.5145L49.9496 16.5045ZM54.4096 22.0645H57.5097
                    C57.6508 22.3982 57.8972 22.6767 58.2114 22.8572C58.5256 23.0378 
                    58.8902 23.1106 59.2496 23.0645C60.4796 23.0645 61.1197 22.4045 61.1197 21.4245
                    V19.6245H61.1297C60.8647 20.185 60.4364 20.6521 59.901 20.9645C59.3655 
                    21.2769 58.7479 21.4198 58.1297 21.3745C55.7796 21.3745 54.2197 19.5845 54.2197
                    16.5045C54.2197 13.4245 55.7097 11.5045 58.2197 11.5045C58.8538 11.4706 
                    59.4828 11.6341 60.0201 11.9726C60.5574 12.3111 60.9764 12.8079 61.2197
                    13.3945H61.1297V11.5845H64.3097V21.3245C64.3097 23.6745 62.2297 25.1445 
                    59.1697 25.1445C56.3897 25.1345 54.6096 23.8645 54.4296 22.0745L54.4096
                    22.0645ZM61.1297 16.5045C61.1297 14.9345 60.4096 13.9445 59.2696 13.9445C58.1297 13.9445 57.4296 14.9445 57.4296 16.5045
                    C57.4296 18.0645 58.1196 18.9845 59.2696 18.9845C60.4196 18.9845 61.1297 18.1345 61.1297 16.5045ZM65.5796 16.5045
                    C65.5796 13.2845 67.5796 11.3545 70.5796 11.3545C73.5796 11.3545 75.5796 13.2845 
                    75.5796 16.5045C75.5796 19.7245 73.6396 21.6745 70.5796 21.6745
                    C67.5196 21.6745 65.5997 19.7945 65.5997 16.5045H65.5796ZM72.3996 16.5045C72.3996 14.7345 71.6997 13.6945 70.6097 13.6945
                    C69.5197 13.6945 68.8296 14.6945 68.8296 16.5045C68.8296 18.3145 69.5097 19.3045 
                    70.6097 19.3045C71.7097 19.3045 72.4197 18.3145 72.4197 16.5145
                    L72.3996 16.5045Z" fill="#394149"/>
            </svg>`

// IDENTIFICATION ICONS //
export const identificationBgShadow = (color: string) =>
    `<svg width="527" height="389" viewBox="0 0 527 389" xmlns="http://www.w3.org/2000/svg">
                <path 
                    d="M248.855 15.823C185.15 86.3384 1.27402 352.775 1.27402 352.775L374.372 47.2254C380.626 42.1033 385.546 35.5409 388.708 28.1008
                    L436.373 -84.0423C436.373 -84.0423 312.561 -54.6925 248.855 15.823Z" 
                    fill="${color}" fill-opacity="0.08"/>
                <path 
                    d="M199.984 -0.207532C156.079 84.0732 45.1911 388.215 45.1911 388.215L329.364 -1.41088C334.127 -7.9425 337.236 -15.5318 338.424 -23.5284
                    L356.326 -144.059C356.326 -144.059 243.888 -84.4882 199.984 -0.207532Z" 
                    fill="${color}" fill-opacity="0.08"/>
            </svg>`

export const identificationBg = `<svg width="545" height="163" viewBox="0 0 545 163" xmlns="http://www.w3.org/2000/svg">
                <path d="M141.5 163H0V0H596V52H201.5L141.5 163Z" fill="#F3F4F6"/>
            </svg>`

// FOOTER ICONS //
export const mailIcon = (color: string) =>
    `<svg width="14" height="10" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg">
                <path 
                    d="M13.3333 1.25C13.3333 0.5625 12.7333 0 12 0H1.33333C0.6 0 0 0.5625 0 1.25V8.75C0 9.4375 0.6 10 1.33333 10H12
                    C12.7333 10 13.3333 9.4375 13.3333 8.75V1.25ZM12 1.25L6.66667 4.375L1.33333 1.25H12ZM12 8.75H1.33333V2.5L6.66667 5.625L12 2.5V8.75Z" 
                    fill="${color}"/>
            </svg>`

export const netIcon = (color: string) =>
    `<svg width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg">
                <path 
                    d="M7.51301 6C7.55301 5.67 7.58301 5.34 7.58301 5C7.58301 4.66 7.55301 4.33 7.51301 4H9.20301C9.28301 4.32 9.33301 4.655 9.33301 5
                    C9.33301 5.345 9.28301 5.68 9.20301 6H7.51301ZM6.62801 8.78C6.92801 8.225 7.15801 7.625 7.31801 7H8.79301
                    C8.31301 7.825 7.54801 8.465 6.62801 8.78ZM6.50301 6H4.16301C4.11301 5.67 4.08301 5.34 
                    4.08301 5C4.08301 4.66 4.11301 4.325 4.16301 4H6.50301
                    C6.54801 4.325 6.58301 4.66 6.58301 5C6.58301 5.34 6.54801 5.67 6.50301 6ZM5.33301 8.98C4.91801 8.38 4.58301 7.715 4.37801 7H6.28801
                    C6.08301 7.715 5.74801 8.38 5.33301 8.98ZM3.33301 3H1.87301C2.34801 
                    2.17 3.11801 1.53 4.03301 1.22C3.73301 1.775 3.50801 2.375 3.33301 3ZM1.87301 7H3.33301
                    C3.50801 7.625 3.73301 8.225 4.03301 8.78C3.11801 8.465 2.34801 7.825 1.87301 7ZM1.46301 6C1.38301 5.68 1.33301 5.345 1.33301 5
                    C1.33301 4.655 1.38301 4.32 1.46301 4H3.15301C3.11301 4.33 3.08301 4.66 3.08301 5C3.08301 5.34 3.11301 5.67 3.15301 6H1.46301ZM5.33301 1.015
                    C5.74801 1.615 6.08301 2.285 6.28801 3H4.37801C4.58301 2.285 4.91801 1.615 5.33301 1.015ZM8.79301 3H7.31801
                    C7.15801 2.375 6.92801 1.775 6.62801 1.22C7.54801 1.535 8.31301 2.17 8.79301 3ZM5.33301 0C2.56801 0 0.333008 2.25 0.333008 5
                    C0.333008 6.32608 0.859792 7.59785 1.79747 8.53553C2.26177 8.99983 2.81296 9.36812 3.41959 9.6194C4.02622 9.87067 4.6764 10 5.33301 10
                    C6.65909 10 7.93086 9.47322 8.86854 8.53553C9.80622 7.59785 10.333 6.32608 10.333 5C10.333 4.34339 10.2037 3.69321 9.95241 3.08658
                    C9.70113 2.47995 9.33283 1.92876 8.86854 1.46447C8.40425 1.00017 7.85305 0.631876 7.24643 0.380602C6.6398 0.129329 5.98962 0 5.33301 0Z" 
                    fill="${color}"/>
            </svg>`

export const phoneIcon = (color: string) =>
    `<svg width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg">
                <path 
                    d="M9.77745 6.94444C9.11079 6.94444 8.38856 6.83333 7.77745 6.61111C7.7219 6.61111 7.66634 6.61111 7.61079 6.61111
                    C7.44412 6.61111 7.33301 6.66667 7.2219 6.77778L5.99967 8C4.44412 7.16667 3.11079 5.88889 2.33301 4.33333L3.55523 3.11111
                    C3.7219 2.94444 3.77745 2.72222 3.66634 2.55556C3.49967 1.94444 3.38856 
                    1.22222 3.38856 0.555556C3.38856 0.277778 3.11079 0 2.83301 0H0.888563
                    C0.610786 0 0.333008 0.277778 0.333008 0.555556C0.333008 5.77778 4.55523 10 9.77745 10C10.0552 10 10.333 9.72222 10.333 9.44444V7.5
                    C10.333 7.22222 10.0552 6.94444 9.77745 6.94444ZM1.44412 1.11111H2.27745C2.33301 1.61111 2.44412 2.11111 2.55523 2.55556L1.88856 3.22222
                    C1.66634 2.55556 1.49967 1.83333 1.44412 1.11111ZM9.2219 8.88889C8.49967 8.83333 7.77745 8.66667 7.11079 8.44445L7.77745 7.77778
                    C8.2219 7.88889 8.7219 8 9.2219 8V8.88889Z" fill="${color}"/>
            </svg>`

export const footerBgShadow = (color: string) =>
    `<svg width="473" height="344" viewBox="0 0 473 344" xmlns="http://www.w3.org/2000/svg">
                <path 
                    d="M345.855 165.823C282.15 236.338 98.274 502.775 98.274 502.775L471.372 197.225C477.626 192.103 482.546 185.541 485.708 178.101
                    L533.373 65.9577C533.373 65.9577 409.561 95.3075 345.855 165.823Z" 
                    fill="${color}" fill-opacity="0.08"/>
                <path 
                    d="M296.984 149.792C253.079 234.073 142.191 538.215 142.191 538.215L426.364 148.589C431.127 142.058 434.236 134.468 435.424 126.472
                    L453.326 5.94126C453.326 5.94126 340.888 65.5118 296.984 149.792Z" 
                    fill="${color}" fill-opacity="0.08"/>
            </svg>`
