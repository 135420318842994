import { PriceOfferPdfProps, PriceOfferPdfVariant } from '../PriceOfferPdf'
import { PriceOfferStepTwoDetailHeader } from '../../../../../services/api/price-offer/PriceOffer.types'
import { calendarIcon, calendarTimeIcon, headerBg, headerBgShadow, logoPlaceholder } from '../icons/PriceOfferPdfIcons'
import { convertSvgToBase64 } from '../../../../../utils/Helper'
import { formatDate } from '../../../../../utils/DateHelper'
import React, { useMemo } from 'react'

export type PriceOfferPdfHeaderColors = {
    primary: string
    secondary: string
    textColor: string
    textColorSecondary: string
    backgroundColor: string
}

export type PriceOfferPdfHeaderProps = {
    section: PriceOfferStepTwoDetailHeader
    images: PriceOfferPdfProps['images']
    showDivider?: boolean
    colors: PriceOfferPdfHeaderColors
    variant: PriceOfferPdfVariant
}

const PriceOfferPdfHeader = ({
    section: { createdAt, number, tendererLogoUrl, orders },
    images,
    colors,
    variant
}: PriceOfferPdfHeaderProps) => {
    const isVariantSvg = variant === 'variant-svg'
    const isVariantBg = variant === 'variant-bg'

    const tendererLogo = images.find(item => {
        return item.url === tendererLogoUrl
    })
    const orderNumbers = orders.map(item => {
        return item.number
    })

    const svgCalendar = useMemo(() => convertSvgToBase64(calendarIcon(colors.secondary)), [colors.secondary])
    const svgCalendarTime = useMemo(() => convertSvgToBase64(calendarTimeIcon(colors.secondary)), [colors.secondary])
    const svgBg = useMemo(() => convertSvgToBase64(headerBgShadow(colors.secondary)), [colors.secondary])
    const svgBgShadow = useMemo(() => convertSvgToBase64(headerBg), [])
    const svgLogo = useMemo(() => convertSvgToBase64(logoPlaceholder), [])

    return (
        <>
            <header className='price-offer-pdf-header'>
                {isVariantBg && <img src={svgBgShadow} alt='Logo' className='header-background' />}
                <div className='pdf-row'>
                    <div className='pdf-section-left-container pdf-column pdf-logo-container pdf-center-column-middle'>
                        <div className='pdf-logo-second-container'>
                            <img
                                src={tendererLogo?.base64 || svgLogo}
                                alt={tendererLogo?.alt || 'Logo'}
                                className='pdf-logo'
                            />
                        </div>
                    </div>
                    {isVariantSvg && <img src={svgBg} alt='Logo' className='header-svg-background' />}
                    <div className='pdf-section-right-container pdf-column pdf-header-text pdf-center-column-middle'>
                        <div className='pdf-title'>Cenová nabídka {number}</div>
                        <div className='pdf-row'>
                            <div className='pdf-header-number'>
                                {isVariantSvg && (
                                    <>
                                        <img
                                            src={svgCalendar}
                                            alt='Calendar'
                                            className='pdf-icon'
                                            width='12'
                                            height='12'
                                        />
                                        <span>Vystaveno {formatDate(createdAt)}</span>
                                        <img
                                            src={svgCalendarTime}
                                            alt='Time'
                                            className='pdf-icon'
                                            width='12'
                                            height='12'
                                        />
                                        <span>Platnost 30 dní</span>
                                    </>
                                )}
                                <img src={svgCalendar} alt='Logo' className='pdf-icon' width='12' height='12' />
                                <span>{orderNumbers.join(' | ')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default PriceOfferPdfHeader
