import { PriceOfferPdfIdentificationItem } from './identification/PriceOfferPdfIdentificationItem'
import { PriceOfferPdfVariant } from '../PriceOfferPdf'
import {
    PriceOfferStepTwoLayoutIdentificationSection,
    PriceOfferStepTwoLayoutIdentificationSectionPerson
} from '../../../../../services/api/price-offer/PriceOffer.types'
import { convertSvgToBase64 } from '../../../../../utils/Helper'
import { identificationBg, identificationBgShadow } from '../icons/PriceOfferPdfIcons'
import React, { useMemo } from 'react'

export type PriceOfferPdfIdentificationColors = {
    primary: string
    secondary: string
    textColor: string
    textColorSecondary: string
    backgroundColor: string
}

export type PriceOfferPdfIdentificationProps = {
    section: PriceOfferStepTwoLayoutIdentificationSection
    showDivider?: boolean
    colors: PriceOfferPdfIdentificationColors
    variant: PriceOfferPdfVariant
}

const PriceOfferPdfIdentification = ({
    section: {
        data: { realizationAddress, tenderer, taker }
    },
    colors,
    variant
}: PriceOfferPdfIdentificationProps) => {
    const isVariantSvg = variant === 'variant-svg'
    const isVariantBg = variant === 'variant-bg'

    const renderSection = (
        key: string,
        person: PriceOfferStepTwoLayoutIdentificationSectionPerson,
        className?: string,
        title?: string
    ) => {
        return (
            <div className={`pdf-column ${className}`}>
                <div className='pdf-subtitle'>{person.company}</div>
                <div className='pdf-subtitle'>{!person.company ? person.name : null}</div>
                <div className='pdf-subtitle-secondary'>{title}</div>
                <ul className='pdf-text-attributes'>
                    {key === 'Tenderer' && (
                        <>
                            <PriceOfferPdfIdentificationItem label='Adresa' data={person.address} />
                            <PriceOfferPdfIdentificationItem label='Tel' data={person.phone} isPhoneNumber={true} />
                        </>
                    )}
                    {key === 'Taker' && (
                        <>
                            <PriceOfferPdfIdentificationItem label='Tel' data={person.phone} isPhoneNumber={true} />
                            <PriceOfferPdfIdentificationItem label='Email' data={person.email} />
                        </>
                    )}
                </ul>
            </div>
        )
    }

    const svgBg = useMemo(() => convertSvgToBase64(identificationBgShadow(colors.secondary)), [colors.secondary])
    const svgBgShadow = useMemo(() => convertSvgToBase64(identificationBg), [])

    return (
        <>
            <div className='pdf-section-identification-container'>
                {isVariantBg && <img src={svgBgShadow} alt='Logo' className='identification-background' />}
                <div className='pdf-row'>
                    {renderSection('Tenderer', tenderer, 'left', 'Dodavatel')}
                    {renderSection('Taker', taker, 'center', 'Objednatel')}
                    <div className='pdf-column right'>
                        <div className='pdf-subtitle'>Místo realizace</div>
                        <p className='pdf-subtitle-secondary'>Specifikováno objednatelem</p>
                        <p className='pdf-text-attributes'>
                            <strong>{realizationAddress}</strong>
                        </p>
                    </div>
                </div>
            </div>
            {isVariantSvg && (
                <div className='pdf-identification-divider'>
                    <img src={svgBg} alt='Logo' className='identification-svg-background' />
                </div>
            )}
        </>
    )
}

export default PriceOfferPdfIdentification
