import { formatCurrency } from '../../../../../../utils/Helper'
import React from 'react'

type PriceOfferPdfCustomerPriceTotalsProps = {
    totalWithoutVat: number
    vatRate: number
    totalPrice: number
    currency: string
}

const PriceOfferPdfCustomerPriceTotals = ({
    totalWithoutVat,
    vatRate,
    totalPrice,
    currency
}: PriceOfferPdfCustomerPriceTotalsProps) => (
    <div className='pdf-table-container pdf-font-bold'>
        <table className='customer-price-totals'>
            <tbody>
                <tr>
                    <th className='pdf-text-large'>Celková cena bez DPH</th>
                    <td className='pdf-text-large-normal'>{formatCurrency(totalWithoutVat, currency)}</td>
                </tr>
                <tr>
                    <th className='pdf-text-large'>DPH</th>
                    <td className='pdf-text-large-normal'>{vatRate} %</td>
                </tr>
                <tr>
                    <th className='pdf-text-large'>Celková cena vč. DPH</th>
                    <td className='pdf-text-large-normal'>{formatCurrency(totalPrice, currency)}</td>
                </tr>
            </tbody>
        </table>
    </div>
)

export default PriceOfferPdfCustomerPriceTotals
