import { booleanAsStringToCzech } from '../../../../../../utils/Helper'
import React from 'react'

export type PriceOfferPdfTableRowProps = {
    row: [
        {
            attribute: string
            value: string | number | boolean
        }
    ]
    isVertical?: boolean
}

const PriceOfferPdfTableRow = ({ row }: PriceOfferPdfTableRowProps) => (
    <>
        {row.map(cell => (
            <td key={cell.attribute} className='pdf-subtitle-secondary'>
                {booleanAsStringToCzech(cell.value)}
            </td>
        ))}
    </>
)

export default PriceOfferPdfTableRow
