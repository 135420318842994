import { PriceOfferOrderHeader } from '../../../../../../services/api/price-offer/PriceOffer.types'
import React from 'react'

export type PriceOfferPdfTableHeaderProps = {
    headers: PriceOfferOrderHeader[]
}

const PriceOfferPdfTableHeader = ({ headers }: PriceOfferPdfTableHeaderProps) => (
    <tr className='table-header-horizontal'>
        {headers.map(item => (
            <th key={item.attributeName}>
                <span className='table-horizontal-header-font' dangerouslySetInnerHTML={{ __html: item.label }} />
            </th>
        ))}
    </tr>
)

export default PriceOfferPdfTableHeader
