import { PriceOfferOrderHeader, PriceOfferOrderTag } from '../../../../../../services/api/price-offer/PriceOffer.types'
import { booleanAsStringToCzech } from '../../../../../../utils/Helper'
import PriceOfferPdfTableTags from './PriceOfferPdfTableTags'
import React from 'react'

export type PriceOfferPdfTableVerticalProps = {
    data: {
        idOrderConnection: number
        productName: string
        headers: PriceOfferOrderHeader[]
        tags: PriceOfferOrderTag[]
        attributes: [
            [
                {
                    attribute: string
                    value: string | number | boolean
                }
            ]
        ]
    }
    flatHeaders: (headers: PriceOfferOrderHeader[]) => PriceOfferOrderHeader[]
}

const PriceOfferPdfTableVertical = ({ data, flatHeaders }: PriceOfferPdfTableVerticalProps) => (
    <>
        <div className='pdf-tech-spec-section'>
            <div className='pdf-text-large-normal'>Technická specifikace</div>
            <PriceOfferPdfTableTags tags={data.tags} />
        </div>
        <div className='pdf-row'>
            <div className='pdf-column'>
                <table className='table-vertical'>
                    <tbody>
                        {flatHeaders(data.headers).map((item, index) => (
                            <tr key={index}>
                                <th key={item.attributeName} className='table-header-vertical pdf-text-left'>
                                    <span
                                        className='table-vertical-header-font'
                                        dangerouslySetInnerHTML={{ __html: item.label }}
                                    />
                                </th>
                                {data.attributes.map((row, rowIndex) => {
                                    return (
                                        <td
                                            key={`${rowIndex}_${item.attributeName}`}
                                            className='table-body-vertical pdf-text-left'
                                        >
                                            {booleanAsStringToCzech(row[index].value)}
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </>
)

export default PriceOfferPdfTableVertical
