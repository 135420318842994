import { PriceOfferPdfProps, PriceOfferPdfVariant } from '../PriceOfferPdf'
import { PriceOfferStepTwoLayoutFileSection } from '../../../../../services/api/price-offer/PriceOffer.types'
import React, { useCallback } from 'react'
import useTheme from '../../../../../hooks/useTheme'

export type PriceOfferPdfImagesProps = {
    section: PriceOfferStepTwoLayoutFileSection
    images: PriceOfferPdfProps['images']
    variant: PriceOfferPdfVariant
}

const PriceOfferPdfImages = ({ section: { data: imageList }, images, variant }: PriceOfferPdfImagesProps) => {
    const { getPageImage } = useTheme()
    const defaultImages = [
        getPageImage('okenisite1.png'),
        getPageImage('okennisite2.png'),
        getPageImage('okennisite3.png')
    ]
    const getImageProps = useCallback(
        (url: string, index: number) => {
            const image = images.find(image => image.url === url)
            let borderRadiusStyle = {}
            if (imageList.length === 3 && variant === 'variant-svg') {
                if (index === 0) {
                    borderRadiusStyle = { borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }
                } else if (index === 2) {
                    borderRadiusStyle = { borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }
                }
            }
            return {
                src: image ? image.base64 : defaultImages[index % defaultImages.length],
                alt: image ? image.alt : 'Default image',
                style: borderRadiusStyle
            }
        },
        [images, defaultImages, imageList.length]
    )

    return (
        <div className='pdf-section-container pdf-images-table'>
            {imageList.map((section, index) => {
                const imageProps = section.fileUrl
                    ? getImageProps(section.fileUrl, index)
                    : { src: defaultImages[index % defaultImages.length], alt: 'Default image', style: {} }
                const backgroundImageStyle = {
                    backgroundImage: `url('${imageProps.src}')`,
                    ...imageProps.style
                }

                return (
                    <div className='pdf-images-column' key={index}>
                        <div className='pdf-image-container'>
                            {section.fileUrl ? (
                                <div style={backgroundImageStyle} />
                            ) : (
                                <img src={imageProps.src} alt={imageProps.alt} style={imageProps.style} />
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default PriceOfferPdfImages
