import { PriceOfferPdfVariant } from '../PriceOfferPdf'
import {
    PriceOfferStepTwoDetailHeader,
    PriceOfferStepTwoLayoutIdentificationSection
} from '../../../../../services/api/price-offer/PriceOffer.types'
import {
    calendarIcon,
    calendarTimeIcon,
    footerBgShadow,
    mailIcon,
    netIcon,
    phoneIcon
} from '../icons/PriceOfferPdfIcons'
import { convertSvgToBase64 } from '../../../../../utils/Helper'
import { formatDate } from '../../../../../utils/DateHelper'
import { formatPhoneNumber } from '../../../../../utils/StringHelper'
import React, { useMemo } from 'react'

export type PriceOfferPdfFooterColors = {
    primary: string
    secondary: string
    textColor: string
    textColorSecondary: string
    backgroundColor: string
}

export type PriceOfferPdfFooterProps = {
    section: PriceOfferStepTwoLayoutIdentificationSection
    data: PriceOfferStepTwoDetailHeader
    colors: PriceOfferPdfFooterColors
    variant: PriceOfferPdfVariant
}

const PriceOfferPdfFooter = ({
    section: {
        data: { taker }
    },
    data: { createdAt },
    colors,
    variant
}: PriceOfferPdfFooterProps) => {
    const isVariantSvg = variant === 'variant-svg'
    const isVariantBg = variant === 'variant-bg'

    const svgMail = useMemo(() => convertSvgToBase64(mailIcon(colors.secondary)), [colors.secondary])
    const svgNet = useMemo(() => convertSvgToBase64(netIcon(colors.secondary)), [colors.secondary])
    const svgPhone = useMemo(() => convertSvgToBase64(phoneIcon(colors.secondary)), [colors.secondary])
    const svgBg = useMemo(() => convertSvgToBase64(footerBgShadow(colors.secondary)), [colors.secondary])
    const svgCalendar = useMemo(() => convertSvgToBase64(calendarIcon(colors.secondary)), [colors.secondary])
    const svgCalendarTime = useMemo(() => convertSvgToBase64(calendarTimeIcon(colors.secondary)), [colors.secondary])

    return (
        <footer className='price-offer-pdf-footer'>
            <div className='pdf-row footer-divider'>
                <div className='pdf-column footer-left-column'>
                    {isVariantSvg && (
                        <div>
                            <div className='footer-prepared pdf-subtitle-secondary-bold'>
                                Nabídku zpracoval {taker.name}
                            </div>
                        </div>
                    )}
                    <div className='footer-contact-info'>
                        <div className='footer-icons'>
                            <img src={svgMail} alt='Mail' className='pdf-icon-footer' width='16' height='12.5' />
                            {taker.email}
                            <img src={svgNet} alt='Net' className='pdf-icon-footer-second' width='15' height='14' />
                            <span>svetoken.cz </span>
                            <img src={svgPhone} alt='Phone' className='pdf-icon-footer-second' width='14' height='14' />
                            {formatPhoneNumber(taker.phone)}
                            {isVariantBg && (
                                <>
                                    <img src={svgCalendar} alt='Calendar' className='pdf-icon' width='12' height='12' />
                                    <span>Vystaveno {formatDate(createdAt)}</span>
                                    <img src={svgCalendarTime} alt='Time' className='pdf-icon' width='12' height='12' />
                                    <span>Platnost 30 dní</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {isVariantSvg && <img src={svgBg} alt='Logo' className='footer-svg-background' />}
                <div className='pdf-column footer-right-column'>
                    {isVariantSvg && (
                        <div>
                            <div className='pdf-subtitle-secondary-bold'>Cenová nabídka</div>
                            {/* TODO page count */}
                            {/* <div className='footer-page-info'>
                            <div className='footer-page'>strana 1/2</div>
                        </div> */}
                        </div>
                    )}
                    {/* TODO page count */}
                    {/* {isVariantBg && (
                        <div>
                            <div className='pdf-subtitle-secondary-bold'>strana 1/2</div>
                        </div>
                    )} */}
                </div>
            </div>
        </footer>
    )
}

export default PriceOfferPdfFooter
